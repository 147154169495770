import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state
const initialState = {
    signs: [],
  loading: false,
  error: null,
};

// Define the async thunk to fetch symptoms from the API
export const fetchSigns = createAsyncThunk(
  'signs/fetchSigns',
  async (searchParams: any = '') => {
    try {
      // const count = 3;
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/signs/getAllSign`,
        {
          params: {
            // count,
            search: searchParams // Add searchParams to the query
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data?.data; // Assuming API returns an array of symptoms
    } catch (error : any) {
      return error.response.data;
    }
  }
);

// Create the slice
const signsSlice = createSlice({
  name: 'signsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSigns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSigns.fulfilled, (state, action) => {
        state.loading = false;
        state.signs = action.payload; // Assuming response has a 'data' property
      })
      .addCase(fetchSigns.rejected, (state : any,  action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default signsSlice.reducer;

// Selectors
export const selectSigns = (state : any) => state.signsSlice?.signs;
export const selectLoading = (state : any) => state.signsSlice?.loading;
export const selectError = (state : any) => state.signsSlice?.error;
