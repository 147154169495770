import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import DynamicTable from "../../Dynamic-table";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import CustomAutocomplete from "../../autocomplete-field";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
function formatDate(timestamp: string): string {
  const date = dayjs(timestamp);
  return date.format("DD/MM/YYYY - h:mma").toUpperCase();
}
const ChangeRoomServices = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  const [isForUpdate, setIsForUpdate] = useState(false);
  const [roomData, setRoomData] = useState<any>(null);
  const [currentRoomDetails, setCurrentRoomDetails] = useState<any>(null);
  const [selectedRoomCategory, setSelectedRoomCategory] = useState<any>(null);
  const [selectedBedNo, setselectedBedNo] = useState<any>(null);
  const [selectedFloors, setSelectedFloors] = useState<any>();
  const [RoomCategoryOptions, setRoomCategoryOptions] = useState<any>();
  const [selectedRoom, setSelectedRoom] = useState<any>(null);
  const [floorsOptions, setFloorsOptions] = useState<any>();
  const [roomsOptions, setRoomsOptions] = useState<any>();
  const [shiftData, setShiftData] = useState<any>({
    admittedDate: "",
    admittedTime: "",
    roomCategory: "",
    floorNo: "",
    roomName: "",
    bedNo: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const ReturnToDashboard = () => {
    navigate("/ipd-dashboard");
  };
  // Fetch all allocated room data
  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_IPD_API_URL}/ipd-room-allocation/getAllAllocatedRoom/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "*/*",
            },
          }
        );
        setRoomData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching room data", error);
      }
    };

    fetchRoomData();
  }, [id]);
  useEffect(() => {
    const fetchCurrentRoomDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_IPD_API_URL}/ipd-room-allocation/getCurrentAllocatedRoom/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "*/*",
            },
          }
        );
        setCurrentRoomDetails(response?.data?.data);
      } catch (error) {
        console.error("Error fetching current room details", error);
      }
    };

    fetchCurrentRoomDetails();
  }, [id]);
  const handleShiftSubmit = async () => {
    const token = localStorage.getItem("token");
    const postData = {
      roomId: selectedRoom?._id,
      bedNo: selectedBedNo?.bedNo,
      ipdAdmissionId: id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-room-allocation/allocateRoom`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        ReturnToDashboard();
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      console.error("Error shifting room", error);
      toast.error("Failed to shift the room. Please try again.");
    }
  };
  const getRoomCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IPD_API_URL}/room-category/getAllRoomCategoryByPagination?count=500000&pageNo=1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response?.data?.isSuccess) {
        // toast.success(response.data.message)
        setRoomCategoryOptions(response?.data?.data || []);
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };
  const getRoom = async (floorId?: any, categoryId?: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IPD_API_URL}/room/findAllRooms?floorId=${
          selectedFloors?._id || floorId
        }&roomCategoryId=${
          selectedRoomCategory?._id || categoryId
        }&count=500000&pageNo=1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response?.data?.isSuccess) {
        const filteredRoom = response?.data?.data?.filter(
          (item: any) => item.status !== "Booked"
        );
        setRoomsOptions(filteredRoom || []);
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };
  const getFloorOfWhichHaveTheCategory = async (id?: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IPD_API_URL}/room/getFloorsByCategoryId/${
          selectedRoomCategory?._id || id
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response?.data?.isSuccess) {
        setFloorsOptions(response.data?.data || []);
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };

  const handleAutocompleteChangeOfCategory = (
    event: React.ChangeEvent<unknown>,
    newValue: any
  ) => {
    setSelectedRoomCategory(newValue);
    setSelectedFloors("");
    setselectedBedNo("");
    setSelectedRoom("");
  };
  const handleAutocompleteChangeFloors = (
    event: React.ChangeEvent<unknown>,
    newValue: any
  ) => {
    setSelectedFloors(newValue);
    setselectedBedNo("");
    setSelectedRoom("");
  };
  const handleAutocompleteChangeRoom = (
    event: React.ChangeEvent<unknown>,
    newValue: any
  ) => {
    setSelectedRoom(newValue);
  };
  const handleAutocompleteChangeBedNo = (
    event: React.ChangeEvent<unknown>,
    newValue: any
  ) => {
    setselectedBedNo(newValue);
  };

  useEffect(() => {
    getRoomCategory();
    if (!isForUpdate) {
      setSelectedFloors("");
      setselectedBedNo("");
      setSelectedRoom("");
    }
  }, [selectedRoomCategory]);

  useEffect(() => {
    getRoom();
    if (!isForUpdate) {
      setselectedBedNo("");
      setSelectedRoom("");
    }
  }, [selectedFloors]);

  useEffect(() => {
    getFloorOfWhichHaveTheCategory();
  }, [selectedRoomCategory]);
  useEffect(() => {
    const date = new Date();

    // Format date as YYYY-MM-DD
    const formattedDate = date.toISOString().split("T")[0];

    // Format time as HH:MM
    const formattedTime = date.toTimeString().slice(0, 5);

    setCurrentDate(formattedDate);
    setCurrentTime(formattedTime);
  }, []);
  console.log("asfdasd", selectedRoom);
  return (
    <Box>
      <Box
        sx={{
          background: "#fff",
          p: 0.5,
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "#000", fontWeight: "600", px: 2 }}
        >
          Room
        </Typography>
        <Box>
          <IconButton onClick={ReturnToDashboard}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
      </Box>

      <Box mt={1} sx={{ background: "#fff", borderRadius: "8px", p: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={5} sm={12} xs={12}>
            <Box
              sx={{
                padding: 1,
                border: "1px solid #F3F3F3",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6">Current</Typography>
              <Divider sx={{ mt: 0.5, mb: 0.5 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Admitted Date :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    className="search-field"
                    size="small"
                    value={
                      currentRoomDetails?.allotedDate
                        ? new Date(currentRoomDetails.allotedDate)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Time :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Time"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    className="search-field"
                    size="small"
                    value={
                      currentRoomDetails?.allotedDate
                        ? new Date(currentRoomDetails.allotedDate)
                            .toISOString()
                            .split("T")[1]
                            .slice(0, 5)
                        : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Room Category :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Room Category"
                    variant="outlined"
                    className="search-field"
                    size="small"
                    value={currentRoomDetails?.category?.roomCategoryName || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Floor No :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Floor No"
                    type="text"
                    variant="outlined"
                    className="search-field"
                    size="small"
                    value={currentRoomDetails?.floor?.floorName || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Room Name :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Room Name"
                    type="text"
                    variant="outlined"
                    className="search-field"
                    size="small"
                    value={currentRoomDetails?.room?.roomName || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Bed No :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Bed No"
                    type="number"
                    variant="outlined"
                    className="search-field"
                    size="small"
                    value={currentRoomDetails?.bedNo || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                      Facilities{" "}
                      <span style={{ fontSize: "9px" }}>(Read Only)</span>
                    </Typography>
                    <Select
                      value={currentRoomDetails?.room?.facilities[0] || ""}
                      sx={{
                        borderRadius: "12px",
                        border: "0px solid #D8D8D8",
                        background: "#F5F5FB",
                        fontSize: "12px",
                        height: "38px",

                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    >
                      {currentRoomDetails?.room?.facilities?.map(
                        (option: any, index: any) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            md={1}
            sm={0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReplyIcon
              sx={{
                fontSize: "30px",
                color: "#055FFC",
                transform: "scaleX(-1)",
              }}
            />
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <Box
              sx={{
                padding: 1,
                border: "1px solid #F3F3F3",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6">Shift To</Typography>
              <Divider sx={{ mt: 0.5, mb: 0.5 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Admitted Date:
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Date"
                    type="date"
                    value={currentDate}
                    InputLabelProps={{ shrink: true }}
                    className="search-field"
                    size="small"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    Time:
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Time"
                    type="time"
                    value={currentTime}
                    InputLabelProps={{ shrink: true }}
                    className="search-field"
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
                  >
                    Room Category:
                  </Typography>
                  <CustomAutocomplete
                    options={RoomCategoryOptions || ""}
                    value={selectedRoomCategory}
                    onChange={(event, value) =>
                      handleAutocompleteChangeOfCategory(event, value)
                    }
                    getOptionLabel={(option: any) =>
                      option?.roomCategoryName || ""
                    }
                    placeholder="Select Room Category"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
                  >
                    Floors:
                  </Typography>
                  <CustomAutocomplete
                    disabled={!selectedRoomCategory ? true : false}
                    options={floorsOptions || ""}
                    value={selectedFloors}
                    onChange={(event, value) =>
                      handleAutocompleteChangeFloors(event, value)
                    }
                    getOptionLabel={(option: any) => option?.floorName || ""}
                    placeholder="Select Floors"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
                  >
                    Room Name.:
                  </Typography>
                  <CustomAutocomplete
                    disabled={!selectedFloors ? true : false}
                    options={roomsOptions}
                    value={selectedRoom}
                    onChange={(event, value) =>
                      handleAutocompleteChangeRoom(event, value)
                    }
                    getOptionLabel={(option: any) => option?.roomName || ""}
                    placeholder="Select Room"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
                  >
                    Bed No.:
                  </Typography>
                  <CustomAutocomplete
                    disabled={!selectedRoom?.allBedStatus ? true : false}
                    options={selectedRoom?.allBedStatus?.filter(
                      (bed: any) => bed?.status === "Available"
                    )}
                    value={selectedBedNo}
                    onChange={(event, value) =>
                      handleAutocompleteChangeBedNo(event, value)
                    }
                    getOptionLabel={(option: any) => option?.bedNo || ""}
                    placeholder="Select Bed"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                      Facilities{" "}
                      <span style={{ fontSize: "9px" }}>(Read Only)</span>
                    </Typography>
                    <Select
                      value={selectedRoom ? selectedRoom?.facilities[0] : ""}
                      size="small"
                      fullWidth
                      sx={{
                        borderRadius: "12px",
                        border: "1px solid #D8D8D8",
                        background: "#F5F5FB",
                        fontSize: "12px",
                        boxShadow: "none",
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    >
                      {selectedRoom?.facilities?.map(
                        (facility: any, idx: any) => (
                          <MenuItem key={idx} value={facility}>
                            {facility}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            md={1}
            sm={0}
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleShiftSubmit}
            >
              Shift
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "8px" }}>
          <DynamicTable
            headers={[
              "SN",
              "Room Category",
              "Floor",
              "Room No.",
              "Bed No.",
              "Allocation Date&Time",
              "Leave Date&Time",
              "Charges",
              "Status",
            ]}
            data={roomData?.map((patient: any, index: any) => ({
              SN: index + 1,
              RoomCategory: patient?.category?.roomCategoryName,
              Floor: patient?.floor?.floorName,
              RoomName: patient?.room?.roomName,
              BedNo: patient?.bedNo,
              AllocationDateandTime: formatDate(patient?.allotedDate),
              LeaveDateandTime: formatDate(patient?.allotedDate),
              Charges: patient?.room?.chargesPerDay,
              Status: patient?.allocationStatus,
            }))}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeRoomServices;
