import { combineReducers } from 'redux';
import doctorSlice from './docSlice.js'; // Update the import path if necessary
import userCategoriesSlice from './admin/getAllCategories.js'
import getUserByIdSlice from './admin/getCategoriesDataById.ts'
import testGroupSlice from './pathology/getAllTestGroups.ts'
import testGroupSliceByID from './pathology/gettestByGroupId.ts'
import  departmentsSlice  from './admin/getAllDepartments.js';
import profileSlice from './profileSlice.js';
import assignedDoctorsSlice from './assignedDoctorsSlice.js';
import reportsDocSlice  from './reportsDocSlice.js';
import accessConfigSlice from './config/accessConfigSlice.js';
import projectProfileSlice from './projectProfileSlice.js';
import labDepartmentSlice from './pathology/getAllDepartment.ts'
import testUnitsSlice from './pathology/getAllTestUnits.js'
import getAdminPathologySlice from './pathology/getAdminPathologySlice.js';
import diagnosisSlice from './OPD/Admin/diagnosisSlice.js';
import symptomsSlice from './OPD/Admin/symptomsSlice.ts'
import signsSlice from './OPD/Admin/signsSlice.ts'
import allDoctorSlice from './allDoctorSlice.js'
const rootReducer = combineReducers({
  doctorSlice,
  userCategoriesSlice,
  getUserByIdSlice,
  testGroupSlice,
  testGroupSliceByID,
  departmentsSlice,
  profileSlice,
  assignedDoctorsSlice,
  reportsDocSlice,
  accessConfigSlice,
  projectProfileSlice,
  labDepartmentSlice,
  testUnitsSlice,
  getAdminPathologySlice,
  diagnosisSlice,
  symptomsSlice,
  allDoctorSlice,
  signsSlice
  
  
  // Add other reducers here if you have any
});

export default rootReducer;